import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import ShopOverviewCard from '../shop-overview-card/shop-overview-card';
import ShopProductDetailed from "../shop-product-detailed/shop-product-detailed";
import ShopProductList from "../shop-product-list/shop-product-list";
// import ShopDetailedStickyButtons from '../shop-detailed-sticky-buttons/shop-detailed-sticky-buttons';
import { useDispatch, useSelector } from "react-redux";
import * as action from "./redux/action";
import "./detailed-product-grid.scss";

export default function DetailedProductGrid({
  product,
  vendor,
  variant,
  history,
  postcode,
  type,
}) {
  // const [sectionViewToggle, setSectionViewToggle] = useState(false);
  const dispatch = useDispatch();
  const sectionViewToggle = useSelector(
    (state) => state.productDetails.sectionViewToggle
  );
  const [products, setProducts] = useState([]);
  const params = useParams();
  const { variantId, productId } = params;

  const setSectionViewToggle = (flag) => {
    dispatch(action.setSectionViewToggle(flag));
  };

  useEffect(() => {
    if (variantId) setSectionViewToggle(true);
    else setSectionViewToggle(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantId]);

  useEffect(() => {
    if (product && type !== "shops") setSectionViewToggle(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, type]);

  return (
    <React.Fragment>
      <div
        className={`detailed-product-grid detailed-product-grid-inner-${sectionViewToggle}`}
      >
        <div
          className={`detailed-product-mobile-left toggle-product-left-${sectionViewToggle}`}
        >
          {/* left */}
          {/* <ShopOverviewCard history={history} vendor={vendor} /> */}
          <ShopProductList
            postcode={postcode}
            history={history}
            vendor={vendor}
            product={product}
            products={products}
            productId={productId}
            setProducts={setProducts}
            showDetailView={setSectionViewToggle}
          />
        </div>
        <div
          className={`detailed-product-mobile-left toggle-product-right-${sectionViewToggle}`}
        >
          {/* right */}
          <ShopProductDetailed
            key={"spd_" + product + variant}
            history={history}
            product={product}
            variant={variant}
            postcode={postcode}
            vendor={vendor}
            products={products}
            goBack={setSectionViewToggle}
            viewToggle={sectionViewToggle}
            type={type}
            showDetailView={setSectionViewToggle}
          />
        </div>
      </div>
      {/* <ShopDetailedStickyButtons history={history} highstreet={params.highstreet} type={params.type} /> */}
    </React.Fragment>
  );
}
