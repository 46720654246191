import { actionType } from "./reducer";
// future use
// import store from '../../../redux/store';

export const setSectionViewToggle = (flag) => {
  return {
    type: actionType.SET_SECTION_VIEW_TOGGLE,
    payload: { flag },
  };
};
