import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Request from '../../../helpers/request'

export const OrdersList = () => {
    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1)

    useEffect(() => {
        Request(true).get(`/self/orders?limit=3&page=${page}`).then(e => setOrders(c => c.concat(e.data.data)))
    }, [page])

    return (
        orders ? <>
            <table className="table" style={{borderSpacing: '0 30px'}}>
                <tbody>
                    {
                        orders.map(order => {
                            return (
                                <Order order={order} />
                            )
                        })
                    }
                </tbody>
            </table>
            <h4 className="text-center color-cornflower-blue" onClick={() => setPage(page + 1)} style={{cursor: 'pointer'}}>See more</h4>
        </> : null
    )
}

const Order = ({order}) => {
    return (
        <tr key={`order-row-${order._id}`}>
            <td style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                <p className="bold"><span className="color-purpleish">{order._id.substr(-6)}</span> {moment(order.paymentConfirmedAt).format('DD/MM/YYYY')}</p>
                {
                    order.items.map(item => {
                        return (
                            <p key={`order-item-${order._id}-product-${item.product._id}`}>{item.product.name}</p>
                        )
                    })
                }
            </td>
            <td>
                <p>{order.vendor.name}</p>
            </td>
            <td>
                <p>£{order.price.total}</p>
            </td>
        </tr>
    )
}