import React, { useCallback } from "react";
import { useEffect } from "react";
import request from "../../helpers/request";
import ShopProductListItem from "../shop-product-list-item/shop-product-list-item";

import "./shop-product-list.scss";

export default function ShopProductList({
  history,
  vendor,
  highstreet,
  showDetailView,
  products,
  setProducts,
  productId,
  postcode,
}) {
  useEffect(() => {
    async function getProducts() {
      setProducts(
        // NOTE: revert when needed only paticuler vender list
        // (await request(false).get(`/products?vendor=${vendor}`)).data.data
        (await request(false).get(`/products?postcode=${postcode}`)).data.data
      );
    }

    getProducts();
  }, [postcode, setProducts]);

  const changed = useCallback(
    (value) => {
      history.push(`/products/${postcode}/shops/${vendor}/${value}`);
      showDetailView(true);
    },
    [postcode, vendor, history, showDetailView]
  );

  return (
    <>
      {products.map((product) => {
        return (
          <ShopProductListItem
            key={product._id}
            highstreet={highstreet}
            history={history}
            {...product}
            showDetailView={showDetailView}
            productId={productId}
            handleCardClick={() => changed(product._id)}
          />
        );
      })}
    </>
  );
}
