import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '../components/container/container';
import Login from '../components/login/Login';
import Register from '../components/login/Register';
import Page from '../components/page/page';

export default function LoginOrRegister ({ ...props })
{
    const defaultRoute = props.location.state?.isShowCreateAccount ? 'register' : 'login'
    const [stage, setStage] = React.useState(defaultRoute);
    const history = useHistory()
    const redirectUri = new URLSearchParams(history.location.search).get('redirect_uri')

    useEffect(() => {
        // console.log('Will redirect you to', redirectUri)
    }, [redirectUri])

    return (
        <Page>
            <Container>
                { stage === 'login' || stage === 'forgotten' ? <Login redirectUri={redirectUri} loginOrRegister={stage} setLoginOrRegister={setStage} /> : null }
                { stage === 'register' ? <Register source={props.source} redirectUri={redirectUri} history={history} setLoginOrRegister={setStage} /> : null }
            </Container>
        </Page>
    )
}
