import React, { useMemo } from "react";
// import { Link } from 'react-router-dom';
import { useEffect } from "react";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactModal from "react-modal";
import request from "../../helpers/request";
import ShopProductCustomisation from "../shop-product-customisation/shop-product-customisation";
// import session from '../../stores/session';

import "./shop-product-detailed.scss";
import BasketState from "../../stores/basket";
// import StickyActionButtons from '../sticky-action-buttons/sticky-action-buttons';
import useViewport from "../../hooks/useViewport";
// import clockIcon from '../../assets/product-detailed/clock-icon.svg';
// import SimpleSelect from 'react-simple-styleable-select';

const ADDED_BUTTON_TEXT = "Added - edit customisation?";
const ADDING = "Adding...";
const BUTTON_TEXT = "Customise & Add to basket";

export default function ShopProductDetailed({
  product: productId,
  variant: variantId,
  history,
  goBack,
  products,
  type,
  vendor: vendorId,
  postcode,
  showDetailView,
}) {
  const [product, setProduct] = useState({});
  const [variant, setVariant] = useState({});
  const [vendor, setVendor] = useState({});

  // Find basket item index to show button text
  const [basketIndex] = useState(
    BasketState.items.findIndex(({ id }) => id === productId)
  );
  // if found isAddedInCart set to true
  const [isAddedInCart, setIsAddedInCart] = useState(basketIndex !== -1);

  const [price, setPrice] = useState(0);

  const [customisation, setCustomisation] = useState([]);
  const [showCustomisation, setshowCustomisation] = useState(false);

  const [displayImage, setDisplayImage] = useState(0);

  const [disableBasketButton, setDisableBasketButton] = useState(false);
  const [buyButtonText, setBuyButtonText] = useState(
    isAddedInCart ? ADDED_BUTTON_TEXT : BUTTON_TEXT
  );
  // const [displayAddToCartText, setDisplayAddToCartText] = useState(false);

  const [sizeProducts, setSizeProducts] = useState([]);
  const [deliverySlots, setDeliverySlots] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [currentDay, setCurrentDay] = useState({});

  useEffect(() => {
    let returnedList = [],
      sizeProducts = [];
    if (product.products) sizeProducts = product.products;
    else if (products.length > 0) sizeProducts = products[0].products;

    sizeProducts.forEach((p) =>
      returnedList.push({ value: p._id, label: p.title })
    );
    setSizeProducts(returnedList);
  }, [products, product]);

  const changed = (value) => {
    if (!value) {
      history.push(`/products/${postcode}/shops/${vendorId}/${productId}`);
    } else {
      history.push(
        `/products/${postcode}/shops/${vendorId}/${productId}/${value}`
      );
      showDetailView(true);
    }
  };

  // eslint-disable-next-line
  // const [displayImage, DisplayImage] = useState(0)
  const { width } = useViewport();
  useEffect(() => {
    if (productId) {
      request(false)
        .get(`/products/${productId}`)
        .then((data) => setProduct(data.data));
    }
  }, [productId]);

  // Select requested variant

  useEffect(() => {
    if (!product.name) return;

    let productVariant;
    if (variantId) {
      productVariant = product.products.find((v) => v._id === variantId);
    }
    if (!productVariant) {
      productVariant = product.products[0];
    }

    const filteredBasketProducts = BasketState.items.filter(
      ({ id }) => id === productId
    );

    const isFoundedSameProduct = filteredBasketProducts.findIndex(
      ({ raw: { variant } }) => variant.title === productVariant.title
    );
    if (isFoundedSameProduct === -1) {
      setBuyButtonText(BUTTON_TEXT);
      setIsAddedInCart(false);
    }
    setVariant(productVariant);

    // Set default customization
    const defaultCustomization = [];
    productVariant.customisation.forEach((cus) => {
      if (cus.options && Array.isArray(cus.options) && cus.options.length > 0) {
        defaultCustomization.push({
          option: cus._id,
          value: cus.options[0]._id,
        });
      }
    });
    setCustomisation(defaultCustomization);
  }, [variantId, product, productId]);

  // Select product if none requested

  useEffect(() => {
    if (product.name || productId || !products.length || !products.length > 0)
      return;
    if (window.screen.width > 1000) {
      history.replace(
        `/products/${postcode}/${type}/${vendorId}/${products[0]._id}`
      );
    }
  }, [product, products, productId, postcode, type, vendorId, history]);

  useEffect(() => {
    if (!product.vendor) return;

    request(false)
      .get(`/vendors/${product.vendor}`)
      .then((e) => {
        setVendor(e.data);
      });
  }, [product.vendor]);

  const imageThumbnails = useMemo(() => {
    if (!product.images || !product.images.length) return;
    return product.images
      .filter((_, index) => {
        return index !== displayImage;
      })
      .map((_, index) => {
        return index;
      });
  }, [displayImage, product]);

  useEffect(() => {
    let productVariant;
    if (product && product.products) {
      if (variantId)
        productVariant = product.products.find((v) => v._id === variantId);
      else if (!productVariant) productVariant = product.products[0];
    }

    request(false)
      .post("/helpers/product-price", {
        product: product,
        variant: productVariant,
        options: [],
      })
      .then((e) => {
        let basePrice = e.data.total ? e.data.total : 0;

        customisation.forEach((c) => {
          const o = variant.customisation
            .find((a) => a._id === c.option)
            .options.find((a) => a._id === c.value)?.priceModifier;
          basePrice += o || 0;
        });

        setPrice(basePrice);
      });
  }, [variant, customisation, product, variantId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addToBasket = (quick = false) => {
    setDisableBasketButton(true);
    const added = BasketState.addItemToBasket(
      product,
      variant,
      customisation,
      quick
    );

    if (!added) {
      setDisableBasketButton(false);
      setIsAddedInCart(false);
      setBuyButtonText(BUTTON_TEXT);
      return;
    }

    // setDisplayAddToCartText(true);
    setBuyButtonText(ADDING);
    setTimeout(() => {
      setDisableBasketButton(false);
      setIsAddedInCart(true);
      setBuyButtonText(ADDED_BUTTON_TEXT);
    }, 800);
  };

  let defaultSize = variantId;
  if (!defaultSize)
    defaultSize = sizeProducts.length > 0 ? sizeProducts[0].value : "";

  useEffect(() => {
    request(true)
      .get(`/helpers/delivery-slots${product.quickDelivery ? "?quick=1" : ""}`)
      .then((e) => {
        setDeliverySlots(e.data);
      });
  }, [product]);

  const days = useMemo(() => {
    const ret = [];
    Object.keys(deliverySlots).forEach((day) => {
      ret.push({
        id: deliverySlots[day].id,
        day: deliverySlots[day].day,
        available: deliverySlots[day].available,
        date: deliverySlots[day].date,
      });
    });
    return ret;
  }, [deliverySlots]);

  useEffect(() => {
    if (!days || !days.length) return;
    let firstDay = days.filter((a) => a.available)[0];
    setCurrentDay(firstDay);
  }, [days]);

  const updateCustomisation = () => {
    let cartItems = JSON.parse(JSON.stringify(BasketState.items));
    if (cartItems[basketIndex]) {
      cartItems[basketIndex].customisations = customisation;
      BasketState.setItems(cartItems);
    }
  };

  const onConfirmSelection = () => {
    if (!isAddedInCart) {
      addToBasket();
    } else {
      updateCustomisation();
    }
    setshowCustomisation(false);
  };
  return [
    <div className="shop-product-detailed">
      <div className="shop-product-detailed-left">
        <div className="shop-product-detailed-left-details">
          {/* <h3>{vendor && vendor.name}</h3> */}
          <p className="shop-product-detailed-left-details-title">
            {product.name}
          </p>
          <p className="shop-product-detailed-left-details-get-it">
            {/* Get it {currentDay.day || ""} */}
            From {vendor.name}
          </p>
          {/* <p className='shop-product-detailed-left-details-size'>
            {variant && variant.title ? `Size: ${variant.title}` : ''}
          </p> */}
        </div>
        {/* <div className='shop-product-detailed-left-size'>          
        {sizeProducts.length > 0 && (
          <SimpleSelect
            onChange={changed}
            defaultValue={defaultSize}
            value=''
            options={sizeProducts}
            hasSharpCorners
            fixedHeight
            style={{ color: 'black' }}
          />
        )}
        </div> */}
        <div className="shop-product-detailed-left-images">
          <div className="shop-product-detailed-left-images-thumbnail">
            <div
              className="shop-product-detailed-left-images-thumbnail-container"
              style={{
                backgroundImage: `url(${
                  product.images && product.images.length
                    ? product.images[displayImage].url
                    : ""
                })`,
              }}
            >
              {/* <img
                alt='Product Thumbnail'
                src={
                  product.images && product.images.length
                    ? product.images[displayImage].url
                    : ''
                }
              /> */}
            </div>
          </div>
          <div className="shop-product-detailed-left-images-grid">
            {imageThumbnails && imageThumbnails.length
              ? imageThumbnails.map((indx) => {
                  return (
                    <div className="shop-product-detailed-left-images-grid-item">
                      <img
                        alt={product.name}
                        onClick={() => setDisplayImage(indx)}
                        src={product.images[indx].url}
                      />
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
      <div className="shop-product-detailed-right">
        {/* {product.quickDelivery ? (
          <div className='shop-product-detailed-right-delivery-note'>
            <img
              src={clockIcon}
              alt='Localo same day delivery available'
              className='clock-icon'
            />
            <span className='same-day'>Same Day</span> delivery available
          </div> 
        ) : null } */}
        <div className="shop-product-detailed-right-description">
          <p>{variant.shortDescription}</p>
          <div className="shop-product-detailed-right-tags">
            {sizeProducts.map((size) => (
              <div
                className={`shop-product-detailed-right-tags-item ${
                  defaultSize === size.value ? "active" : ""
                }`}
                onClick={() => changed(size.value)}
                title={size.label}
              >
                {size.label}
              </div>
            ))}
          </div>
          {/* <div className='shop-product-detailed-right-delivered'>
            This box can be delivered as soon as  {currentDay}
          </div>
          <div className='shop-product-detailed-left-size'>          
            {sizeProducts.length > 0 && (
              <SimpleSelect
                onChange={changed}
                defaultValue={defaultSize}
                value=''
                options={sizeProducts}
                hasSharpCorners
                fixedHeight
                style={{ color: 'black' }}
              />
            )}
          </div> */}
          <div className="shop-product-detailed-right-price">
            <p>Your current box £{price.toFixed(2)}</p>
          </div>
          {
            <button
              className={
                isAddedInCart
                  ? `button button-detail-view-basket button-height`
                  : `button button-green-solid button-height`
              }
              // ${
              // variant.customisation && variant.customisation.length
              //   ? 'button-green-border'
              //   : 'button-green-solid'
              // }`}
              onClick={() => setshowCustomisation(true)}
              disabled={disableBasketButton}
            >
              <i className="add-to-cart-icon" /> {buyButtonText}
            </button>
          }
          {
            <button
              className={
                isAddedInCart
                  ? `button button-green-solid button-height marginTop`
                  : `button button-detail-view-basket button-height marginTop`
              }
              onClick={() => history.push("/basket")}
              disabled={disableBasketButton}
            >
              {"View basket"}
            </button>
          }
          {/* <ReactMarkdown source={variant.longDescription} /> */}
        </div>
        <div className="shop-product-detailed-right-shop">
          <div
            className={`shop-product-detailed-right-hero ${
              !product.quickDelivery ? "no-quick-delivery" : ""
            }`}
          >
            {/* {JSON.stringify(BasketState.items.length)} */}
            {/* <div className="shop-product-detailed-right-price">
              <p>Your current box £{price.toFixed(2)}</p>
            </div> */}
            <div className="shop-product-detailed-right-buttons">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {variant.customisation && variant.customisation.length
                  ? null
                  : // <button
                    //   className="button button-green-solid"
                    //   onClick={() => setshowCustomisation(true)}
                    // >
                    //   <span className="customise-link-text">
                    //     Don’t want standard? Customise!
                    //   </span>
                    //   <span className="customise-btn-text">Customise</span>
                    // </button>
                    null}
                {/* {product.quickDelivery ? (
                  <button
                    className='button button-green-solid fast'
                    onClick={() => addToBasket(true)}
                    disabled={disableBasketButton}
                  >
                    <i className='clock-icon' />
                    {disableBasketButton ? buyButtonText : 'Get it fast'}
                  </button>
                ) : null} */}

                <ReactMarkdown source={variant.longDescription} />
              </div>
            </div>
            {/* {displayAddToCartText && <AddToCartConfirmation />} */}
          </div>
          <p className="shop-product-detailed-right-shop-title">
            About {vendor ? vendor.name : "the shop"}
          </p>
          <ReactMarkdown
            source={vendor ? vendor.longBio : "##### No Shop Description"}
          />
        </div>
      </div>
      {/* <StickyActionButtons
        goBack={goBack}
        customizationModal={setshowCustomisation}
        disabled={disableBasketButton}
        addToBasket={addToBasket}
        isLoggedIn={session.token ? true : false}
      /> */}
    </div>,
    <ReactModal
      isOpen={showCustomisation}
      style={{
        content: {
          width: width < 700 ? "80%" : "500px",
          height: width < 700 ? "80vh" : "70vh",
          top: "50%",
          left: "50%",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
      onRequestClose={() => setshowCustomisation(false)}
    >
      <ShopProductCustomisation
        customisation={customisation}
        setCustomisation={setCustomisation}
        variant={variant}
        closeModal={setshowCustomisation}
        onConfirmSelection={onConfirmSelection}
      />
    </ReactModal>,
  ];
}

// const AddToCartConfirmation = () => {
//   return (
//     <div className="addtocart_confirm-container">
//       <p className="addtocart_confirm-text">
//         The item was added to the basket.
//       </p>
//       <Link className="addtocart_confirm-link" to="/basket">
//         View basket
//       </Link>
//     </div>
//   );
// };
