import { createStore, combineReducers, compose } from "redux";
import basket from "../components/basket/redux/reducer";
import productDetails from "../components/detailed-product-grid/redux/reducer";

const rootReducer = combineReducers({
  basket,
  productDetails,
});

const store = createStore(
  rootReducer,
  {},
  compose(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);
export default store;
