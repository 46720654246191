export const actionType = {
  SET_SECTION_VIEW_TOGGLE: "SET_SECTION_VIEW_TOGGLE",
};

const INITIAL_STATE = {
  sectionViewToggle: false,
};

const productDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_SECTION_VIEW_TOGGLE:
      return {
        ...state,
        sectionViewToggle: action.payload.flag,
      };
    default:
      return state;
  }
};

export default productDetails;
