import React, { useEffect, useMemo, useState } from "react";
import Request from "../../helpers/request";
import SimpleSelect from "react-simple-styleable-select";

export default function SelectPaymentCard({ onChange, setIsSavedCard, setAddNewCard }) {
  const [cards, setCards] = useState([]);
  const [defaultCard, setDefaultCard] = useState(null);
  let selectRef = React.createRef();
  useEffect(() => {
    Request(true)
      .get("/self/cards")
      .then((e) => {
        if (e.data.cards.length > 0) {
          setIsSavedCard(true);
        } else {
          setIsSavedCard(false);
        }
        setCards(e.data.cards);
        setDefaultCard(e.data.default);
        onChange(e.data.default);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  const cardSelect = useMemo(() => {
    return cards.map((_) => {
      return {
        value: _.id,
        label: `**** **** **** ${_.card.last4}`,
      };
    });
  }, [cards]);

  const removeSelectedCard = (e) => {
    // eslint-disable-next-line no-unused-expressions
    selectRef?.current?.cancelSelection(e)
  }

  return cardSelect.length ? (
    <>
      <div>
        {/* <p
          style={{
            textAlign: "center",
            color: "var(--color-raspberry-sorbet)",
          }}
        >
          Use an existing card
        </p> */}
        <SimpleSelect
          placeholder="Select a payment method"
          ref={(ref) => (selectRef.current = ref)}
          defaultValue={defaultCard}
          options={cardSelect}
          onChange={(_, val) => onChange(val)}
        />
        <div
          className="saved-card-remove-text"
          onClick={removeSelectedCard}
        >
          Remove payment method
        </div>
      </div>
    </>
  ) : null;
}
